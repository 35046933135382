<template>
  <textarea ref="textarea"/>
</template>

<script>
import tinymce from 'tinymce'
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons'
import 'tinymce/models/dom/model'
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/image';

export default {
    components: {},
    props: {
      value: String,
    },
    data() {
      return {
        editor: null,
        height:null
      };
    },
    watch: {
      value: {
        handler() {
          this.setValue(this.value);
        },
        immediate: true,
      },
    },
    mounted() {
      let element = document.getElementById("myAiGenerateContent").parentElement
      let computedStyle = window.getComputedStyle(element)
      this.height = computedStyle.getPropertyValue("height")

      tinymce
        .init({
          // toolbar:"aligncenter alignleft alignright bold fontsize fontfamily",
          toolbar:"alignleft aligncenter alignright",
          font_size_formats: '12px 14px 16px 18px 22px 24px 36px 48px', // 字体大小
          font_family_formats: '微软雅黑=\'微软雅黑\';宋体=\'宋体\';黑体=\'黑体\';仿宋=\'仿宋\';楷体=\'楷体\';隶书=\'隶书\';幼圆=\'幼圆\';Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;sans-serif',
          menubar:false,
          statusbar:false,
          target: this.$refs.textarea,
          language_url: "tinymce/langs/zh_CN.js",
          language: 'zh_CN',
          skin_url:'/tinymce/skins/ui/oxide',
          images_upload_handler: (blobInfo, progress) => {
            return this.uploadImage(blobInfo, progress);
          },
          height: this.height,
          promotion: false
        })
        .then(([editor]) => {
          this.editor = editor;
          this.setValue(this.value);
          editor.on('change keyup undo redo', () => {
            this.$emit('input', editor.getContent());
          });

          let iframe = document.getElementsByTagName("iframe")[0]
          iframe.style.backgroundColor = "transparent"

          let toxTinymce = document.getElementsByClassName("tox tox-tinymce")[0]
          toxTinymce.style.height = this.height
        })
    },
    beforeDestroy() {
      this.editor?.destroy();
    },
    methods: {
      setValue(val) {
        const oldValue = this.editor?.getContent();
        if (typeof val === 'string' && val !== oldValue) {
          this.editor?.setContent(val);
        }
      },
      uploadImage(blobInfo, progress) {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', '/upload');

          xhr.upload.onprogress = (e) => {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = () => {
            if (xhr.status === 403) {
              reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
              return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              reject('HTTP Error: ' + xhr.status);
              return;
            }

            if (xhr.response.code !== 200) {
              reject('Error: ' + xhr.response.message);
              return;
            }

            resolve(xhr.response.data.list[0].file_url);
          };

          xhr.onerror = () => {
            reject(
              'Image upload failed due to a XHR Transport error. Code: ' +
              xhr.status,
            );
          };
          xhr.setRequestHeader('Authorization', this.$cookie.get('Admin-Token'));

          const formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          formData.append('module', 'public');
          formData.append('file_type', 'image');

          xhr.responseType = 'json';
          xhr.send(formData);
        });
      },
    },
  };
</script>

<style scoped>

</style>
