<template>
  <div class="loadingModal" :style="{'height':fatherHeight+'px'}" v-if="progressLoading">
    <!--https://www.zcool.com.cn/work/ZMTczNzk0MDg=.html-->
    <!-- gif原路径：https://img.zcool.cn/community/01967a5a027bdaa801202b0ce052a4.gif-->
    <img :style="{ marginTop:`${fatherHeight * 0.07}px` }" style="height: 300px; width: 400px" src="../../assets/gif/loading.gif">
    <p>{{ loadingText }}</p>
    <el-progress
      :style="{width:'20%',margin:'0 auto',marginTop:'10px'}"
      :type="type"
      :width="70"
      :text-inside="true"
      :stroke-width="30"
      :percentage="percentage"
      :color="colors"
      :show-text="showText"
      :status="percentage === 100 ? undefined:'success'"
      :text-color="percentage > 10 ? '#ffffff' : '#000000'"
    />
  </div>
</template>
<script>

export default {
  data() {
    return {
      percentage: 0,
      acc:0,
      timeStart: 0,
      fatherHeight: 0, // 父级元素高度
      colors: [
        { color: "#1989fa", percentage: 99 },
        { color: "#2BD176", percentage: 100 },
      ],
      progressLoading: false
    };
  },
  props: {
    loadingText:{
      type:String,
      default:"请耐心等待",
    },
    strokeWidth: {
      type: Number,
      default: 24,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    // 进度条状态
    type: {
      type: String,
      default: () => {
        return undefined;
      },
    },
    // 显示状态
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // 获取父级元素的高度，使组件高度与父元素高度相同
    // 相对于父元素定位
    this.$el.parentNode.style.position = "relative";
  },
  methods: {
    // 进度条开始
    start() {
      const that = this
      that.fatherHeight = this.$el.parentNode.offsetHeight
      that.$nextTick(() => {
        that.progressLoading = true;
        that.percentage = 0;
        that.acc = 0
        that.timeStart = setInterval(() => {
          if (that.percentage < 95) {
            that.acc = that.acc + Math.random()*0.6
            that.percentage = Math.floor(that.acc);
          }
        }, 100);
      });
    },
    // 进度条结束
    end() {
      const that = this;
      that.percentage = 100;
      clearInterval(this.timeStart);
      setTimeout(() => {
        that.progressLoading = false;
      }, 300);
    },
  },
  watch: {
    // 监听loading状态控制进度条显示
    loading(value, newValue) {
      const that = this;
      if (value) {
        that.start();
      } else {
        that.end();
      }
    },
  },
};
</script>
<style >
.loadingModal {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
